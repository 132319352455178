import React from 'react';
import style from './GruposViewer.scss';
import { FileOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

const isValidUrl = (link) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(link);
};

function Content({content}) {
  switch (content.content_type_slug.slug) {
    case 'video':
      if (isValidUrl(content.content)) {
        return (
          <div className={style['player-wrapper']}>
            <ReactPlayer
              url={content.content}
              className={style['react-player']}
              width='100%'
              height='100%'
              controls={false}
            />
          </div>
        );
      } else {
        return <div>El link no es válido</div>;
      }
    case 'audio':
      return (
        <>
          <audio controls className={style['audio-element']}>
            <source src={content.content}></source>
          </audio>
        </>
      );
    case 'text':
      return <div dangerouslySetInnerHTML={{ __html: content.content }} />;
    case 'file':
      return (
        <div>
          <a href={content.content.filelink}>
            <span> {'Nombre de archivo'} </span>
            <FileOutlined />
          </a>
        </div>
      );
    default:
      return null;
  }
};

const getContentTypeLabel = (content) => {
  switch (content.content_type_slug.slug) {
    case 'audio':
      return `Audio:`;
    default:
      return '';
  }
};

export default function ContentWithType({ content }) {
  const contentTypeLabel = getContentTypeLabel(content);

  return (
    <>
      {contentTypeLabel && <h4>{contentTypeLabel}</h4>}
      <Content content={content} />
    </>
  );
}
