import React, { useEffect, useState } from "react";
const url = process.env.REACT_APP_BACK_URL
export const Features = (props) => {
  const [feature, setFeature] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url + "/pub/aboutuscontents");
        const json = await response.json();
        setFeature(json.about_us_content.content);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div id='sobreNosotros' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Sobre nosotros</h2>
          <p>
            <td dangerouslySetInnerHTML={{ __html: feature }} />
          </p>
        </div>
      </div>
    </div>
  )
}
