import React, { useRef, useEffect } from 'react';
import TreeView from './TreeView';
import style from './GruposViewer.scss';
import GruposAccordion from './GruposAccordion';
import ContentWithType from './ContentWithType';

const heriarchySwitch = (kind) => {
  switch (kind) {
    case 'axes':
      return 'Dimensiones';
    case 'dimension':
      return 'Problemas';
    case 'problem':
      return 'Recursos';
    default:
      break;
  }
};

const AxisViewer = ({ selected, changeSelected, axes, tree }) => {
  const viewerRef = useRef(null);
  const selectedChildren = tree.filter(
    (treeElem) => treeElem.parent_slug && treeElem.parent_slug.slug === selected.slug
  );
  const accordionElements = tree.filter((element) => element.slug !== selected.slug);

  useEffect(() => {
    viewerRef.current.scrollTop = 0;
  }, [selected]);

  return (
    <div className={style.drawerContainer}>
      <TreeView tree={tree} data={axes} selected={selected} changeSelected={changeSelected} />
      <div ref={viewerRef} className={style.elementInfo}>
        <h3
          className={`${style.grupoTitle}`}
          dangerouslySetInnerHTML={{ __html: selected.title_style ?? selected.title }}></h3>
        <br />
        {selected.contents_with_type.map((content) => (
          <ContentWithType key={content.uuid} content={content} />
        ))}
        <br />
        <br />
        {selectedChildren.length > 0 && (
          <>
            <h4>{heriarchySwitch(selected.element_type_slug.slug)}</h4>
            <GruposAccordion
              elements={accordionElements}
              selectedElements={selectedChildren}
              changeSelected={changeSelected}
              elementType='dimensions'
            />
          </>
        )}
      </div>
      <br />
    </div>
  );
};
export default AxisViewer;
