import { Tree, Breadcrumb } from 'antd'
import { DownOutlined, ApartmentOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import style from './GruposViewer.scss'

const TreeView = ({ data, selected, changeSelected, tree }) => {
  const [drawerVisible, changeVisible] = useState(true)
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onChangeSelected = (selected, e) => {
    changeSelected(selected, e);
  }


  const renderTitle = (node) => {
    const borderStyle = {
      borderRight: '4px solid ' + node.color,
      position: 'absolute',
      left: '0px',
      opacity: 0.5,
      height: '100%',
      width: '1px'
    }
    const textStyle = {
      display: 'block',
      width: '100%',
      paddingBottom: '5px',
      paddingTop: '5px'
    }
    return (
      <span key={node.key} /* onClick={() => toggleExpandNode(node.key)} */>
        <div style={borderStyle}></div>
        <span style={textStyle}>{node.title}</span>
      </span>
    )
  }

  const breadcrumbItemStyles = {
    backgroundColor: 'white',
    cursor: 'pointer'
  }

  const breadcrumbGenerator = () => {
    const title = selected.title.length >= 20 ? `${selected.title.slice(0, 17)}...` : selected.title

    if (selected.element_type_slug) {
      if (selected.element_type_slug.slug === 'axes') {
        return <Breadcrumb.Item style={{backgroundColor: 'white'}}><span title={selected.title}>{title}</span></Breadcrumb.Item>
      }
      if (selected.element_type_slug.slug === 'dimensions') {
        const axis = tree.find(elem => elem.slug === selected.parent_slug.slug)
        return(
          <>
            <Breadcrumb.Item onClick={() => changeSelected(axis)} style={breadcrumbItemStyles}>{axis.title}</Breadcrumb.Item>
            <Breadcrumb.Item style={{backgroundColor: 'white'}}>{selected.title}</Breadcrumb.Item>
          </>
        )
      }
      if (selected.element_type_slug.slug === 'problems') {
        const dimension = tree.find(elem => elem.slug === selected.parent_slug.slug)
        const axis = tree.find(elem => elem.slug === dimension.parent_slug.slug)
        return(
          <>
            <Breadcrumb.Item onClick={() => changeSelected(axis)} style={breadcrumbItemStyles}>{axis.title}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => changeSelected(dimension)} style={breadcrumbItemStyles}>{dimension.title}</Breadcrumb.Item>
            <Breadcrumb.Item style={{backgroundColor: 'white'}}>{selected.title}</Breadcrumb.Item>
          </>
        )
      }
      if (selected.element_type_slug.slug === 'resources') {
        const resource = tree.find(elem => elem.slug === selected.parent_slug.slug)
        const dimension = tree.find(elem => elem.slug === resource.parent_slug.slug)
        const axis = tree.find(elem => elem.slug === dimension.parent_slug.slug)
        return(
          <>
            <Breadcrumb.Item onClick={() => changeSelected(axis)} style={breadcrumbItemStyles}>{axis.title}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => changeSelected(dimension)} style={breadcrumbItemStyles}>{dimension.title}</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => changeSelected(resource)} style={breadcrumbItemStyles}>{resource.title}</Breadcrumb.Item>
            <Breadcrumb.Item style={{backgroundColor: 'white'}}>{selected.title}</Breadcrumb.Item>
          </>
        )
      }
    }
  }


  const breadcrumbStyle = {
    position: 'sticky',top: '-241px', zIndex: '1',
    width: 'fit-content',
    borderRadius: '10px',
    paddingRight: '10px',
    "& span": {
      backgroundColor: 'white'
    }
  }

  return (
    <>
      <Breadcrumb style={breadcrumbStyle}>
          <div>
            <Breadcrumb.Item style={{backgroundColor: 'white'}}>
                <b>
                  <span style={{cursor: 'pointer'}} onClick={() => changeSelected(false)}>CDH</span>
                  <span>  &gt; Diagnóstico</span>
                </b>
            </Breadcrumb.Item>
            {breadcrumbGenerator()}
          </div>
      </Breadcrumb>
      <div onClick={changeVisible} className={style.drawerOpener}>
        {/* <MenuOutlined style={{fontSize:'20px', marginRight:'10px'}}/>
        <span style={{verticalAlign:'top'}}> Mostrar grupos de trabajo</span>*/}
      </div>
      <div style={{width:'30%', float: 'left', height:'fit-content', paddingTop: '4.3rem', display: 'inline-block', position: 'sticky', top: '50px'}}>
        <br />
          <Tree
            /* onExpand={onExpand} */
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            showIcon
            defaultSelectedKeys={[selected.key]}
            defaultExpandParent={true}
            switcherIcon={<DownOutlined />}
            treeData={data}
            onSelect={onChangeSelected}
            titleRender={renderTitle}
            />
      </div>
  </>
  )
}

export default TreeView
