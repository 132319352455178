import React, { useMemo, useRef } from 'react';
import { Collapse } from 'antd';
import ContentWithType from './ContentWithType';

const titleStyle = {
  fontWeight: 'bold',
};

const GruposAccordion = ({ elements = [], selectedElements = [], elementType, bordered = true }) => {
  const firstPanelKey = useMemo(() => {
    const [element] = elements;

    if (!element) return '';

    return `${element.slug}${element.updated_at}${element.created_at}`
  }, [elements]);

  // Ref to the container with elements
  const containerRef = useRef(null);

  const handlePanelChange = panelKey => {
    if (panelKey === firstPanelKey) return;

    setTimeout(() => {
        containerRef.current?.scrollIntoView()
        window.scrollBy(0, -150)
    }, 400);

    containerRef.current.scrollTop = 0
  }

  if (!elements.length) return null

  return (
    <div ref={containerRef}>
      <Collapse accordion bordered={bordered} onChange={handlePanelChange}>
        {selectedElements.map((element) => {
          const filteredElements = elements.filter(
            (filtered) =>
              element.element_type_slug.slug !== filtered.element_type_slug.slug &&
              element.slug !== filtered.slug &&
              filtered.parent_slug?.slug === element.slug
          );

          if (element.element_type_slug.slug !== elementType) return null;

          const panelKey = `${element.slug}${element.updated_at}${element.created_at}`;
          const headerStyle = ['dimensions', 'problems'].includes(element.element_type_slug.slug) ? titleStyle : {};

          return (
            <Collapse.Panel key={panelKey} id={panelKey} header={<span style={headerStyle}>{element.title}</span>}>
              {element.contents_with_type &&
                element.contents_with_type.map((content) => <ContentWithType key={content.uuid} content={content} />)}
              {element.contents_with_type && <br />}
              {element.children_with_type && (
                <GruposAccordion
                  elements={elements}
                  selectedElements={filteredElements}
                  elementType={'problems'}
                  bordered={false}
                />
              )}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default GruposAccordion;
