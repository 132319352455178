import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const url = process.env.REACT_APP_BACK_URL;

export const Gallery = (props) => {
  const [gallery, setGallery] = useState("");
  const [contentModal, setContentModal] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url + "/pub/trainings");
        const json = await response.json();
        setGallery(json.trainings);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setContentModalAndShowModal = (content) => {
    showModal();
    setContentModal(content);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Capacitaciones</h2>
          <p>
            Aquí podrá encontrar las capacitaciones vigentes
          </p>
        </div>
        <div className='container'>
          <Carousel responsive={responsive}  >
            {gallery ? gallery.map((training, key) =>
              <div className={key === 0 ? " item active" : "item"} key={key}>
                <img className="w-100 img-responsive image-gallery" src={training.image} alt={training.order} onClick={() => setContentModalAndShowModal(training.content)} />
              </div>
            ) : <div></div>}
          </Carousel>
        </div>
      </div>
      <Modal title="Capacitación" visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <div dangerouslySetInnerHTML={{ __html: contentModal }} />
      </Modal>
    </div>
  )
}
