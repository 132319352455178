import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import GruposViewer from '../GruposViewer/GruposViewer';
import style from './GruposDeTrabajo.scss';

const { Content } = Layout;
const url = process.env.REACT_APP_BACK_URL;

export const GruposDeTrabajo = () => {
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState('');
  const [axes, setAxes] = useState([]);
  const [tree, setTree] = useState([]);

  const selectAxis = (axis, e) => {
    setSelected(e ? e.node : axis);
  };
  useEffect(() => {
    fetch(`${url}/pub/elements`)
      .then((response) => {
        response.json().then((data) => {
          setTree(data.elements);
          setAxes(
            data.elements
              .filter((element) => element.element_type_slug.slug === 'axes')
              .map((el, i) => {
                el.key = `0-${i + 1}`;
                return el;
              })
          );
        });
      })
      .catch(() => setError('Se encontró un error'));
  }, []);

  return (
    <div id='gruposDeTrabajo' data-offset='300' className={style.gruposDeTrabajo}>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Diagnóstico de problemas</h2>
          <br />
          <p>
            Aquí encontrará herramientas e insumos para responder a los principales desafíos existentes en materia de
            gestión judicial en Argentina y en la región. Las herramientas están organizadas en siete ejes que nuclean
            los problemas recurrentes que enfrentan diariamente las oficinas de gestión judicial. Cada eje problemático
            está organizado a partir de distintas dimensiones, que a su vez contemplan varias preguntas- problema. En
            función del diagnóstico identificado se desplegará una “caja de herramientas” con diversos insumos,
            documentos, videos, podcast y demás material de relevancia. Haga click en el eje que desea profundizar:
          </p>
        </div>
        <Content className={`${style.container} ${selected ? ` ${style['stickyContainer']}` : ''}`}>
          {!error && !selected
            ? axes.map((eje, i) => (
                <Button
                  style={{ backgroundColor: eje.color }}
                  className={style.button}
                  onClick={() => selectAxis(eje)}
                  key={i}>
                  {eje.title}
                </Button>
              ))
            : !error && <GruposViewer tree={tree} axes={axes} selected={selected} changeSelected={selectAxis} />}
        </Content>
      </div>
      {error && <b>Se encontró un error</b>}
    </div>
  );
};
